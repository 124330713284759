'use client';

import React, { useEffect, useRef } from "react";

import Box from "./Box";
import { Player } from "./Player";
import { useEnteredViewport } from "../core/hooks";

function LottieOnEntered({ src, threshold, delay, ...props }) {
  const playerRef = useRef();
  const { ref: containerRef, entered } = useEnteredViewport(threshold);

  useEffect(() => {
    if (entered) {
      if (playerRef.current) {
        if (delay) {
          setTimeout(() => {
            playerRef.current.play();
          }, delay);
        } else {
          playerRef.current.play();
        }
      }
    }
  }, [entered]);

  return (
    <Box ref={containerRef} {...props}>
      <Player
        ref={playerRef}
        src={src}
        loop={false}
        autoplay={false}
        rendererSettings={{
          preserveAspectRatio: "xMidYMid meet",
        }}
        keepLastFrame={true}
      />
    </Box>
  );
}

export default LottieOnEntered;
