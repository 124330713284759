'use client';

import dynamic from 'next/dynamic';

export const Player = dynamic(
  async () => {
    const ReactLottie = await import('@lottiefiles/react-lottie-player');
    return ReactLottie.Player;
  },
  { ssr: false }
);